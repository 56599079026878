import {Input} from "../../Common/Input/Input";
import React, {useEffect, useState} from "react";
import {CreateRequestFooter, useCreateRequestContext} from "../../../containers/AdminFlow/CreateAppointment";
import {Typography} from "../../Common/Typography/Typography";
import {colors} from "../../../assets/colors/colors";
import {placeholdersAddress, sex} from "../../../constants";
import {Radiobutton} from "../../Common/RadioButton/Radiobutton";
import './PatientForm.style.scss'
import {COLORS_CHECKBOX} from "../../Common/CheckboxV2/constants";
import {CheckboxV2} from "../../Common/CheckboxV2";
import {InputAddress} from "../../Common/Input/InputAddress";
import {
    validation18Year, validation6Month,
    validationDOB,
    validationEmail,
    validationIsEmpty,
    validationNameFields,
    validationPhone,
} from "../../../utils/validation";
import {filterObj, lengthObj, toCapitalizeChart1} from "../../../utils/common";
import {usePatientsStepContext} from "./PatientList";
import cn from 'classnames';

const initial = () => ({
    firstName: '',
    lastName: '',
    phone: '',
    birthday: '',
    email: '',
    sex: '',
    home_address: null,
    zipcode: '',
    isMember: null,
})
export const PatientForm = () => {
    const {onNext} = useCreateRequestContext();
    const {patient: initialPatient, setPatient: onPatientUpdated} = usePatientsStepContext();

    const [patient, setPatient] = useState(initialPatient || initial());
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (patient.home_address) {
            setPatient((prev) => ({...prev, zipcode: patient.home_address?.zip_code || patient.zipcode}))
        }
    }, [])


    const isCreation = !initialPatient?.uuid;
    const updateState = (field, value) => {
        setPatient((prev) => ({...prev, [field]: value}));
        setErrors({...errors, [field]: false});
    };
    const updateAddress = (field, value, zipCode) => {
        let newPatientState = {...patient, 'home_address': value, 'zipcode': zipCode}
        const newErrorState = {...errors, 'home_address': false, 'zipcode': false}
        setPatient(newPatientState);
        setErrors(newErrorState);
    };

    const validateForm = () => {
        let errors = validationIsEmpty(patient);
        errors.firstName = errors.firstName || validationNameFields(patient.firstName);
        errors.lastName = errors.lastName || validationNameFields(patient.lastName);
        errors.birthday = validationDOB(patient.birthday) || validation6Month(patient.birthday);
        errors.phone = errors.phone || validationPhone(patient.phone);
        errors.email = errors.email || validationEmail(patient.email);
        errors.isMember = '';
        Object.keys(errors).forEach((key) => {
            if (!initial().hasOwnProperty(key)) {
                delete errors[key];
            }
        })

        return filterObj(errors);
    };

    const onNextPressed = async () => {
        const errors = validateForm();
        if (lengthObj(errors)) {
            setErrors(errors);
            return;
        }

        onPatientUpdated({
            ...(initialPatient || {}),
            ...patient
        });
        onNext();
    }

    return <div className='patient-form'>
        {isCreation && <Typography
            className='patient-form_subtitle'
            capitalize
            color={colors.blackMuted}
            text={'Please Enter Patient Information'}
            variant="h3"
        />}
        <div className="patient-form_row">
            <Input
                placeholder={'First Name'}
                value={patient.firstName}
                maxLength="200"
                error={errors["firstName"]}
                field="firstName"
                onChange={updateState}
            />
            <Input
                placeholder={'Last Name'}
                value={patient.lastName}
                error={errors["lastName"]}
                field="lastName"
                maxLength="200"
                onChange={updateState}
            />
        </div>
        <div className="patient-form_row">
            <Input
                placeholder={'Phone Number'}
                maskChar=" "
                mask="(999) 999-9999"
                value={patient.phone}
                error={errors["phone"]}
                field="phone"
                onChange={updateState}
            />
            <Input
                placeholder={'Date of Birth'}
                value={patient.birthday}
                error={errors["birthday"]}
                field="birthday"
                mask="99/99/9999"
                maskChar={"_"}
                defaultValue={"MM/DD/YYYY"}
                formatChars={{
                    "9": "[0-9]"
                }}
                onChange={updateState}
            />
        </div>
        <div className={cn("patient-form_row", {'half-width': true})}>
            <Input
                placeholder={'Email'}
                className=""
                value={patient.email}
                error={errors["email"]}
                field="email"
                onChange={updateState}
            />
        </div>
        <div className="patient-form_gender">
            <Typography
                text={'Gender'}
                variant="p"
                color={colors.darkMuted}
            />
            <div className="patient-form_gender_options">
                {sex.map((i, index) => (<Radiobutton
                    key={index}
                    label={toCapitalizeChart1(i)}
                    error={errors["sex"]}
                    onClick={() => updateState("sex", i)}
                    className=""
                    checked={patient.sex === i}
                />))}
            </div>
        </div>
        <div className="patient-form_row">
            <InputAddress
                // checkZipcode
                // checkOutOfServedZone
                placeholder={placeholdersAddress.address}
                error={errors["home_address"]}
                field="home_address"
                value={patient.home_address}
                onChange={updateAddress}
                ignoreNewProps={true}
            />
            <Input
                disable
                type="number"
                placeholder={placeholdersAddress.zipcode}
                value={patient.zipcode}
                field="zipcode"
            />
        </div>
        <CheckboxV2
            color={COLORS_CHECKBOX.PRIMARY}
            label={'Member'}
            onChange={updateState}
            field={'isMember'}
            checked={patient.isMember}/>

        <CreateRequestFooter onNext={onNextPressed}/>
    </div>
}